<template>
  <el-form
    :model="form"
    ref="userForm"
    label-width="130px"
    class="company-ruleForm"
    label-position="top"
    status-icon
    v-loading="loading"
  >
    <el-row :gutter="10">
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <el-form-item label="封面图：" prop="coverImageUrl">
          <el-image
            style="width: 150px; height: 100px"
            :src="form.coverImageUrl"
            :preview-src-list="[form.coverImageUrl]"
          >
          </el-image>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <el-form-item label="标题：" prop="title">
          <span>{{ form.title }}</span>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <el-form-item label="摘要：" prop="subTitle">
          <span>{{ form.subTitle }}</span>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <el-form-item label="创建时间：" prop="createdAt">
          <span>{{ form.createdAt | date_time }}</span>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <el-form-item label="创建人：" prop="createdByName">
          <span>{{ form.createdByName }}</span>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <el-form-item label="更新时间：" prop="lastModifiedAt">
          <span>{{ form.lastModifiedAt | date_time }}</span>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <el-form-item label="更新人：" prop="lastModifiedByName">
          <span>{{ form.lastModifiedByName }}</span>
        </el-form-item>
      </el-col>
      <el-col :sm="24">
        <el-form-item label="图文详情：" prop="content">
          <div v-html="form.content"></div>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { GetBlogById } from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
export default {
  props: {
    id: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      form: {},
    };
  },
  watch: {
    id: {
      handler() {
        if (!isEmprty(this.id)) {
          this.getFormData(this.id);
        } else {
          this.form = {};
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    getFormData(id = "") {
      this.loading = true;
      GetBlogById(id)
        .then((res) => {
          this.form = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取详情", err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.company-ruleForm {
  padding: 0 20px;
}
</style>
