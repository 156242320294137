
export function isEmprty(value) {
  if (value === "" || value === null || value === undefined) {
    return true;
  } else {
    return false;
  }
}
export function isNumber(value) {
  if (!value && isNaN(Number(value))) {
    return false;
  } else {
    return true;
  }
}
export function isPhone(value) {
  let pattern = /^[0-9()+-\s_]*$/;
  if (value && pattern.test(value)) {
    return true;
  } else {
    return false;
  }
}
export function isPrice(value) {
  let pattern = /^\d+(\.\d{1,2})?$/;
  if (value && pattern.test(value)) {
    return true;
  } else {
    return false;
  }
}

export function isPhoneNumber(value) {
  if (isEmprty(value)) {
    return false
  }
  // 验证手机号码
  let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  if (reg.test(value)) {
    return true
  } else {
    // 验证带区号的电话号码
    let ref = /^0\d{2,3}-?\d{7,8}$/
    if (ref.test(value)) {
      return true
    } else {
      return false
    }
  }
}

export function checkPhoneNumber(rule, value, callback) {
  // console.log('rule', rule)
  // console.log('value', value)
  if (isEmprty(value)) {
    return callback(new Error("电话号码不能为空"));
  }
  // 验证手机号码
  let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  if (reg.test(value)) {
    callback();
  } else {
    // 验证带区号的电话号码
    let ref = /^0\d{2,3}-?\d{7,8}$/
    if (ref.test(value)) {
      callback();
    } else {
      callback(new Error("电话号码格式不正确，请填写138xxxx8000或者07xx-77xxx77格式的电话号码"));
    }

  }
}

