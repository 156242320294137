<template>
  <section class="filter-wrap base-shadow">
    <BasicFilterWrap
      :moreFilter="showMoreFilter"
      @confrim="onSearch"
      @reset="onResetFilter"
      @more="showMoreFilter = !showMoreFilter"
    >
      <el-form ref="form" :model="tableFilters" label-width="auto">
        <el-row :gutter="20">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="标题">
              <el-input
                v-model="tableFilters.title"
                placeholder="请输入"
                clearable
                @keyup.enter.native="onSearch"
                @clear="onSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="摘要">
              <el-input
                v-model="tableFilters.subTitle"
                placeholder="请输入"
                clearable
                @keyup.enter.native="onSearch"
                @clear="onSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="创建日期">
              <el-date-picker
                v-model="tableFilters.createdAt"
                type="daterange"
                style="width: 100%"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-collapse-transition>
          <el-row :gutter="10" v-if="showMoreFilter">
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="最后修改日期">
                <el-date-picker
                  v-model="tableFilters.lastModifiedAt"
                  type="daterange"
                  style="width: 100%"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="创建人">
                <BasicAsyncSelect
                  v-model="tableFilters.createdById"
                  :asyncObj="{
                    dataFun: GetUsersList,
                  }"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="最后修改用户">
                <BasicAsyncSelect
                  v-model="tableFilters.lastModifiedById"
                  :asyncObj="{
                    dataFun: GetUsersList,
                  }"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-collapse-transition>
      </el-form>
    </BasicFilterWrap>
  </section>
</template>

<script>
import BasicFilterWrap from "@/components/BasicFilterWrap";
import BasicAsyncSelect from "@/components/BasicAsyncSelect";
import { onClearFilter } from "@/utils/common";
import { GetUsersList } from "@/views/users/api";
export default {
  components: {
    BasicAsyncSelect,
    BasicFilterWrap,
  },
  data() {
    return {
      showMoreFilter: false,
      activatedOptions: [
        { id: true, name: "已激活" },
        { id: false, name: "未激活" },
      ],
      tableFilters: {
        createdAt: [],
        lastModifiedAt: [],
        createdById: null,
        lastModifiedById: null,
        departmentId: null,
        activated: null,
        roleIds: [],
        name: "",
        phoneNo: "",
        username: "",
      },
      propObj: {},
    };
  },

  methods: {
    GetUsersList,
    onSearch() {
      let filterParams = {};
      for (const key in this.tableFilters) {
        if (this.propObj[key]) {
          filterParams[this.propObj[key]] = this.tableFilters[key];
        } else {
          filterParams[key] = this.tableFilters[key];
        }
      }
      this.$emit("change", filterParams);
      this.$emit("confirm", filterParams);
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.$emit("change", this.tableFilters);
      this.$emit("clear", this.tableFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-input__inner {
    height: 42px;
    overflow: hidden;
  }
}
</style>
