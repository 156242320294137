<template>
  <div class="basic-filter-component">
    <slot></slot>
    <div class="control-wrap">
      <el-button type="primary" @click="onSearch" icon="el-icon-search"
        >查询</el-button
      >
      <el-button @click="onResetFilter" icon="el-icon-refresh-right"
        >重置</el-button
      >
      <el-button type="text" @click="handleSetMore">
        更多
        <i
          class="el-icon-arrow-up more-icon"
          :class="[moreFilter ? 'active' : '']"
        ></i>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    moreFilter: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSearch() {
      this.$emit("confrim");
    },
    onResetFilter() {
      this.$emit("reset");
    },
    handleSetMore() {
      this.$emit("more");
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-filter-component {
  box-sizing: border-box;
  padding: 15px;
  padding-bottom: 55px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  transition: all 0.3s linear;
  overflow: hidden;
  .control-wrap {
    box-sizing: border-box;
    padding-right: 22px;
    width: 100%;
    height: 40px;
    text-align: right;
    position: absolute;
    bottom: 15px;
    right: 0;
  }
  .more-icon {
    transition: all 0.3s linear;
    &.active {
      transform: rotate(180deg);
    }
  }
}
</style>