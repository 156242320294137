import { apiv1 } from "@/request/apiv1";
import qs from "qs";


// 获取用户的分页列表
export function GetBlogsList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/news?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


export function GetBlogById(id = null) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/news/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新建用户
export function PostBlogs(params = []) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/news`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改指定用户
export function PutBlogById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/news/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 动态启用\禁用用户
 * @param {String,Number} id 
 * @param {Boolean} bool 
 */
export function ToggleDisableById(ids = [], bool = null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/news/disable/${bool}?ids=${ids}`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}



/**
 * 获取启用或者禁用许可
 * @param {String,Number} id 
 */
export function GetDiasbleCheck(ids = []) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/news/can/disable?ids=${ids}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取修改许可
 * @param {String,Number} id 
 */
export function GetUpdateCheck(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/news/${id}/can/update`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}



