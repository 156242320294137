<template>
  <div class="custom-component-wrap">
    <!-- remote
      :remote-method="remoteMethod"
      @visible-change="onOptionsMenuChange" -->
    <el-select
      v-model="selectValue"
      :multiple="multiple"
      :collapse-tags="multiple"
      filterable
      clearable
      reserve-keyword
      :disabled="disabled"
      :placeholder="loading ? '数据请求中...' : placeholder"
      :filter-method="remoteMethod"
      :size="size"
      @change="onSelectChange"
      @blur="onSelectBlur"
      @clear="onSelectClear"
      style="width: 100%"
    >
      <el-option-group key="已选" label="当前选择" :disabled="loading">
        <el-option
          v-for="item in selectOptions"
          :key="item.key"
          :label="
            customKeyName
              ? `${formatKeyNames(item, keyNames)}`
              : `${item[keyName]}`
          "
          :value="item[valueName]"
          :disabled="loading"
        >
        </el-option>
      </el-option-group>
      <el-option-group key="选单" label="选单数据" :disabled="loading">
        <el-option
          v-for="item in currentOptions"
          :key="item.key"
          :label="
            customKeyName ? formatKeyNames(item, keyNames) : item[keyName]
          "
          :value="item[valueName]"
          :disabled="item.disabled"
        >
        </el-option>
      </el-option-group>

      <div class="select-page">
        <el-pagination
          :disabled="loading"
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="optionNumber"
          :current-page="currentPage + 1"
          :total="totalSizes"
        >
        </el-pagination>
      </div>
      <p class="option-tips">
        <i class="el-icon-loading" v-show="loading"></i>
        {{ loading ? "加载中..." : "加载完成" }},
        <span class="text-nav" @click="handleRefresh">刷新</span>
        <br />
        每页显示{{ optionNumber }}条，共{{ totalPages }}页,
        <span class="text-nav" @click="handleRestOptions">重置选单</span>
        <br />
        可通过关键字搜索
      </p>
    </el-select>
  </div>
</template>

<script>
import { isEmprty } from "@/utils/validate";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [String, Array, Number],
      default: () => {
        return "";
      },
    },
    filterKeyName: {
      type: Object,
      default: () => {
        return {};
      },
    },
    keyName: {
      type: [String],
      default: "name",
    },
    customKeyName: {
      type: Boolean,
      default: false,
    },
    keyNames: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    valueName: {
      type: [String],
      default: "id",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: { type: [String], default: "" },
    placeholder: {
      type: String,
      default: "点击选择或输入关键字搜索",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    optionNumber: {
      type: Number,
      default: 30,
    },
    asyncObj: {
      type: Object,
      default() {
        return {
          dataFun: () => {
            return new Promise((resolve) => {
              resolve({
                content: [],
                totalPages: 0,
                totalElements: 0,
                last: false,
              });
            });
          },
        };
      },
    },
  },
  data() {
    return {
      loading: false,
      selectValue: null,
      options: [],
      currentPage: 0,
      totalSizes: 0,
      totalPages: 0,
      isLast: false,
      optionsForValue: [],
      currentAsyncObj: {
        dataFun: this.defaultFun,
      },
      currentFilterKeyName: {},
    };
  },
  computed: {
    currentOptions() {
      return this.options.map((target) => {
        return {
          ...target,
          key: this.createUniqueString() + target[this.valueName],
        };
      });
    },
    selectOptions() {
      return this.optionsForValue.map((target) => {
        return {
          ...target,
          key: this.createUniqueString() + target[this.valueName],
        };
      });
    },
  },
  watch: {
    value: {
      handler() {
        this.initValue();
      },
      immediate: true,
      deep: true,
    },
    // selectValue: {
    //   handler() {
    //     if (isEmprty(this.selectValue)) {
    //       this.getPageListOptions();
    //     }
    //   },
    //   immediate: true,
    // },
    filterKeyName: {
      handler() {
        this.currentFilterKeyName = Object.assign({}, this.filterKeyName);
        this.getPageListOptions();
      },
      deep: true,
    },
    // asyncObj: {
    //   handler() {
    //     this.currentAsyncObj = Object.assign({}, this.asyncObj);
    //     if (this.options.length <= 0) {
    //       this.handleRestOptions();
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  created() {
    this.currentFilterKeyName = Object.assign({}, this.filterKeyName);
    this.currentAsyncObj = Object.assign({}, this.asyncObj);
    this.initValue();
    this.getPageListOptions();
  },
  methods: {
    defaultFun() {
      return new Promise((resolve) => {
        resolve({
          data: {
            content: [],
            totalPages: 0,
            totalElements: 0,
            last: false,
          },
        });
      });
    },
    initValue() {
      if (!isEmprty(this.value) && !this.multiple) {
        this.selectValue = this.value;
      } else if (this.multiple && this.value instanceof Array) {
        this.selectValue = this.value.length > 0 ? this.value : [];
        if (this.selectValue.length <= 0) {
          this.optionsForValue = [];
          return;
        }
      } else {
        this.selectValue = this.value;
        if (!this.selectValue) {
          this.optionsForValue = [];
          return;
        }
      }
      this.setDefaultOptions();
    },

    formatKeyNames(item, keyNames = []) {
      if (keyNames.length <= 0) {
        return this.keyName;
      } else {
        if (item) {
          let names = "";
          keyNames.forEach((key, index) => {
            if (Object.hasOwnProperty.call(item, key)) {
              names += `${item[key]}${
                index < keyNames.length - 1 ? " - " : ""
              }`;
            }
          });
          return names;
        } else {
          return this.keyName;
        }
      }
    },
    setDefaultOptions() {
      this.currentAsyncObj
        .dataFun({
          page: 0,
          size: this.optionNumber,
          [this.valueName]: this.value,
        })
        .then((res) => {
          let { content } = res.data;
          this.optionsForValue = content;
        });
    },
    asyncOption() {
      let optionIds = this.optionsForValue.map((item) => item[this.valueName]);
      this.options = this.options.map((o) => {
        if (optionIds.includes(o[this.valueName])) {
          o.disabled = true;
        } else {
          o.disabled = false;
        }
        return o;
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getPageListOptions();
    },
    getPageListOptions(name = null) {
      if (this.loading) {
        return false;
      }
      this.loading = true;
      let params = {
        page: this.currentPage,
        size: this.optionNumber,
        disabled: false,
      };

      if (!isEmprty(name)) {
        params.page = this.currentPage;
        params[this.keyName] = name;
      }
      if (Object.keys(this.currentFilterKeyName).length > 0) {
        for (const key in this.currentFilterKeyName) {
          params[key] = this.currentFilterKeyName[key];
        }
      }
      this.currentAsyncObj
        .dataFun(params)
        .then((res) => {
          let { content, totalPages, totalElements, last } = res.data;
          this.options = content.map((item) => {
            return {
              ...item,
              disabled: false,
            };
          });
          // this.asyncOption();
          this.totalSizes = totalElements;
          this.totalPages = totalPages;
          this.isLast = last;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    handleRefresh() {
      this.getPageListOptions();
    },
    handleRestOptions() {
      this.currentPage = 0;
      this.getPageListOptions(null);
    },

    onSelectChange(val) {
      let _val = null;
      if (isEmprty(val) && !this.multiple) {
        _val = null;
      } else {
        _val = val;
      }
      this.$emit("input", _val);
      this.$emit("change", _val);
      let target = this.options.find((item) => item[this.valueName] == _val);
      if (target) {
        this.optionsForValue.push(target);
      }
      this.$emit("check", { value: _val, target: target ? target : null });
      // this.asyncOption();
    },
    remoteMethod(query) {
      this.currentPage = 0;
      if (query !== "") {
        this.getPageListOptions(query);
      } else {
        this.getPageListOptions(null);
      }
    },
    onSelectClear() {
      this.handleRestOptions();
    },
    onOptionsMenuChange(value) {
      if (!value) {
        this.getPageListOptions();
      }
    },
    onSelectBlur() {
      if (this.options.length <= 0) {
        this.currentPage = 0;
        this.getPageListOptions(null);
      }
    },
    createUniqueString() {
      const timestamp = +new Date() + "";
      const randomNum = parseInt((1 + Math.random()) * 65536) + "";
      return (+(randomNum + timestamp)).toString(32);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-component-wrap {
  box-sizing: border-box;
  width: auto;
  max-width: 100%;
  overflow: hidden;
}
.option-tips {
  box-sizing: border-box;
  padding: 5px;
  font-size: 13px;
  color: #989898;
  line-height: 24px;
  text-align: center;
}
.select-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #333;
  text-align: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  i {
    flex: 1;
    padding: 10px;
    &:hover {
      background-color: #f1f1f1;
      cursor: pointer;
    }
  }
  span {
    flex: 2;
    padding: 10px;
  }
}
</style>
