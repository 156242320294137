<template>
  <!-- 表格层 -->
  <section class="table-container base-shadow">
    <div class="table-header">
      <div class="table-header-name">
        {{ title }}
        <span class="table-header-desc">
          {{
            this.orderDirection !== null && this.orderProp !== ""
              ? `按照${getSortPropName(
                  tableColumns,
                  this.orderProp
                )},${getOrderNameCn(this.orderDirection)}排序`
              : "按照默认排序"
          }}</span
        >
      </div>
      <div class="table-btn-group">
        <slot name="tableControl"></slot>
        <i
          class="el-icon-refresh table-btn-item"
          title="刷新数据"
          @click="$emit('onRefresh')"
        ></i>
        <i
          v-if="!hiddenClear"
          class="el-icon-close table-btn-item"
          title="清空筛选和排序并刷新"
          @click="$emit('onClearAndRefresh')"
        ></i>
        <el-dropdown
          title="表格密度"
          @command="handleTableSizeChange"
          trigger="click"
          class="table-btn-item"
        >
          <span class="el-dropdown-link">
            <i class="el-icon-s-grid"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              command="medium"
              :class="[tableSize == 'medium' ? 'text-primary' : '']"
              >基本</el-dropdown-item
            >
            <el-dropdown-item
              command="small"
              :class="[tableSize == 'small' ? 'text-primary' : '']"
              >紧凑</el-dropdown-item
            >
            <el-dropdown-item
              command="mini"
              :class="[tableSize == 'mini' ? 'text-primary' : '']"
              >密集</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown
          trigger="click"
          :hide-on-click="false"
          class="table-btn-item mt-r"
        >
          <span class="el-dropdown-link">
            <i class="el-icon-s-operation"></i>
          </span>
          <el-dropdown-menu
            slot="dropdown"
            style="height: 250px; overflow-y: auto"
          >
            <template v-for="item in tableColumns">
              <el-dropdown-item :key="item.lable"
                ><el-checkbox
                  v-model="item.showColumn"
                  @change="onColumnChange()"
                  >{{ item.label }}</el-checkbox
                >
                <!-- <i
                  class="el-icon-back"
                  title="固定在左边"
                  @click="fixLeft(item)"
                ></i>
                <i
                  class="el-icon-right"
                  title="固定在右边"
                  @click="fixRight(item)"
                ></i> -->
              </el-dropdown-item>
            </template>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="multiple-control">
      <slot name="multipleControl"></slot>
    </div>
    <!-- :height="tableHeight" -->
    <div class="table-content">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        max-height="800px"
        :size="tableSize"
        header-cell-class-name="baseTableHeadStyle"
        ref="multipleTable"
        @sort-change="onTableSortChange"
        :default-sort="{ prop: orderProp, order: orderDirection }"
        v-loading="tableLoading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="showSelection"
          type="selection"
          width="55"
          align="center"
          fixed="left"
        />
        <slot>
          <template v-for="column in tableColumns">
            <el-table-column
              v-if="column.showColumn"
              v-bind="column"
              :show-overflow-tooltip="true"
              :key="column.prop"
            >
            </el-table-column>
          </template>
        </slot>
      </el-table>
    </div>
    <div class="custom-table-footer">
      <el-pagination
        v-if="!hiddenPage"
        class="base-table-pagination"
        :disabled="tableLoading"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page + 1"
        :page-sizes="[10, 20, 30, 40, 50, 60]"
        :page-size="size"
        :layout="pageLayout"
        :total="total"
      >
      </el-pagination>
    </div>
  </section>
</template>

<script>
import { getOrderNameCn, getOrderType, getSortPropName } from "@/utils/common";
export default {
  props: {
    title: {
      type: String,
      default: "查询表格",
    },
    showSelection: {
      type: [Boolean],
      default: false,
    },
    orderDirection: {
      type: [String, Object],
      default: () => {
        return null;
      },
    },
    orderProp: {
      type: String,
      default: "",
    },
    tableColumns: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    tableData: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    tableHeight: {
      type: String,
      default: "auto",
    },
    page: {
      type: [Number],
      default: 0,
    },
    size: {
      type: [Number],
      default: 0,
    },
    total: {
      type: [Number],
      default: 0,
    },
    loading: {
      type: [Boolean],
      default: false,
    },
    hiddenClear: {
      type: [Boolean],
      default: false,
    },
    hiddenPage: {
      type: [Boolean],
      default: false,
    },
  },
  data() {
    return {
      tableSize: "medium",
      tableLoading: false,
      pageLayout: "total, sizes, prev, pager, next, jumper",
    };
  },
  watch: {
    loading: {
      handler() {
        this.tableLoading = this.loading;
      },
      immediate: true,
    },
  },
  created() {
    if (this.$getStorage(`${this.$route.name}_tableSize`)) {
      this.tableSize = this.$getStorage(`${this.$route.name}_tableSize`);
    }
    window.addEventListener("resize", () => {
      let width = document.body.clientWidth;
      if (width >= 1600) {
        this.pageLayout = "total, sizes, prev, pager, next, jumper";
      }
      if (width < 1600) {
        this.pageLayout = "total, sizes, prev, pager, next";
      }
      if (width < 1300) {
        this.pageLayout = "total, prev, pager, next";
      }
    });
  },
  methods: {
    getOrderNameCn,
    getOrderType,
    getSortPropName,
    handleTableSizeChange(command) {
      this.$setStorage(`${this.$route.name}_tableSize`, command);
      this.tableSize = command;
    },
    onTableSortChange(data) {
      this.$emit("onSortChange", data);
    },
    handleSelectionChange(selections) {
      this.$emit("onSelectionChange", selections);
    },
    handleSizeChange(val) {
      this.$emit("onSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("onPageChange", val);
    },
    fixLeft(item) {
      if (item.fixed && item.fixed !== null && item.fixed !== "left") {
        item.fixed = "left";
      } else {
        item.fixed = null;
      }
    },
    fixRight(item) {
      if (item.fixed && item.fixed !== null && item.fixed !== "right") {
        item.fixed = "right";
      } else {
        item.fixed = null;
      }
    },
    onColumnChange() {
      this.$setStorage(`${this.$route.name}_tableColumn`, this.tableColumns);
    },
  },
};
</script>

<style lang="scss" scoped>
$base-bg-color: #fff;
.common-bg-color {
  background-color: $base-bg-color;
}
.multiple-control {
  width: 100%;
  margin-bottom: 10px;
  text-align: right;
}
.table-container {
  box-sizing: border-box;
  padding: 0 10px 10px 10px;
  border-radius: 4px;
  @extend .common-bg-color;
  margin:10px ;
  .table-header {
    box-sizing: border-box;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .table-header-name {
      font-size: 15px;
      color: #474747;
    }
    .table-header-desc {
      font-size: 13px;
      color: #989898;
      padding-left: 8px;
    }
  }
  .table-content {
    box-sizing: border-box;
    width: 100%;
    ::v-deep {
      .baseTableHeadStyle {
        // background-color: #98b7fa !important;
        background-color: #f5f7fa !important;
        color: #909399;
        font-weight: 400;
        padding: 2px 0;
        // border-color: #82a5f1;
      }
    }
  }
  .custom-table-footer {
    padding-top: 5px;
    text-align: right;
  }
  .table-btn-group {
    display: flex;
    align-items: center;
    .table-btn-item {
      box-sizing: border-box;
      display: inline-block;
      margin: 0 5px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 20px;
      background-color: hsl(15, 88%, 55%);
      color: #fff;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        background-color: hsl(15, 88%, 45%);
      }
    }
  }
  .base-table-pagination {
    padding-top: 5px;
    margin-top: 5px;
  }
}
</style>